import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import { Button, Body } from '@sumup/circuit-ui';
import { spacing, Col, Row, Grid } from '@sumup/circuit-ui/legacy';
import dynamic from 'next/dynamic';

import { ACTIVATION_TYPES } from '~/domains/sales-leads/components/Popup/constants';
import { ALIGNMENT } from '~/shared/constants';
import ProductsContext from '~/shared/providers/ProductsContext';
import Link from '~/shared/components/Link';
import SectionHeader from '~/shared/components/SectionHeader';
import dataSelector from '~/shared/util/data-selector';
import Price from '~/shared/components/Price';
import { PRICE_FORMATS } from '~/shared/constants/price';

const Popup = dynamic(
  () =>
    import(
      // eslint-disable-next-line max-len
      /* webpackChunkName: 'component-Popup' */ '~/domains/sales-leads/components/Popup'
    ),
  {
    ssr: false,
  },
);

const baseWrapperStyles = () => css`
  text-align: center;
`;

const GridWrapper = styled(Grid)(baseWrapperStyles);

const PriceWrapper = styled('div')(
  ({ theme }) => css`
    margin-bottom: ${theme.spacings.mega};
  `,
);

const baseCtaStyles = () => css`
  display: inline-block;
`;

const ctaStyles = ({ theme, hasAnotherButton }) =>
  hasAnotherButton &&
  css`
    width: 100%;
    margin-left: 0;

    &:not(first-child) {
      margin-top: ${theme.spacings.kilo};
    }

    ${theme.mq.kilo} {
      width: auto;

      &:not(first-child) {
        margin-top: 0;
        margin-left: ${theme.spacings.kilo};
      }
    }
  `;

const CTA = styled(Button)(baseCtaStyles, ctaStyles);
const SpacingContainer = styled('div')(spacing({ bottom: 'giga' }));

/**
 * CallToAction component to prompt an immediate response.
 */
const CallToAction = ({
  headline = '',
  buttonLabel = '',
  buttonUrl = '',
  buttonTrackingId = '',
  buttonOptimizelyFullStackClickEvents = [],
  buttonLabelSecond,
  buttonUrlSecond,
  buttonTrackingIdSecond,
  buttonOptimizelyFullStackClickEventsSecond = [],
  finePrint = '',
  showPricing = false,
  product: productProp = {},
  className,
  index,
  contentType,
  name,
  id,
  popup,
}) => {
  const hasPopup = !isEmpty(popup);
  const [showPopup, setShowPopup] = React.useState(false);
  const { products = {} } = useContext(ProductsContext);

  const triggerPopup = React.useCallback(() => {
    if (!popup) {
      return;
    }

    setShowPopup(!showPopup);
  }, [showPopup, popup]);

  const product = get(productProp?.productId, products) || {};
  const hasFirstButton = buttonLabel && (buttonUrl || hasPopup);
  const hasSecondButton = buttonLabelSecond && buttonUrlSecond;

  return (
    <GridWrapper
      className={className}
      data-selector={dataSelector('section', 'call_to_action')}
      data-elbcontext="component:call_to_action"
    >
      {headline && (
        <SectionHeader
          headline={headline}
          index={index}
          alignment={ALIGNMENT.CENTER}
          trackingContentEntry={{
            contentType,
            contentEntryName: name,
            contentEntryId: id,
          }}
        />
      )}
      <Row>
        <Col span="12">
          {showPricing && (
            <PriceWrapper>
              <Price product={product} format={PRICE_FORMATS.SIZE_62} />
            </PriceWrapper>
          )}
          <SpacingContainer>
            <>
              {hasFirstButton && (
                <Link
                  onClick={triggerPopup}
                  href={hasPopup ? undefined : buttonUrl}
                  trackingId={buttonTrackingId}
                  optimizelyFullStackClickEvents={
                    buttonOptimizelyFullStackClickEvents
                  }
                  trackingContentEntry={{
                    contentType,
                    contentEntryName: name,
                    contentEntryId: id,
                  }}
                >
                  <CTA
                    variant="primary"
                    hasAnotherButton={hasSecondButton}
                    data-selector={dataSelector(
                      'button_primary',
                      'call_to_action',
                    )}
                  >
                    {buttonLabel}
                  </CTA>
                </Link>
              )}
              {hasSecondButton && (
                <Link
                  href={buttonUrlSecond}
                  trackingId={buttonTrackingIdSecond}
                  optimizelyFullStackClickEvents={
                    buttonOptimizelyFullStackClickEventsSecond
                  }
                  trackingContentEntry={{
                    contentType,
                    contentEntryName: name,
                    contentEntryId: id,
                  }}
                >
                  <CTA
                    hasAnotherButton={hasFirstButton}
                    data-selector={dataSelector(
                      'button_secondary',
                      'call_to_action',
                    )}
                  >
                    {buttonLabelSecond}
                  </CTA>
                </Link>
              )}
            </>
          </SpacingContainer>
          <Body size="two" css={spacing({ bottom: 'mega' })}>
            {finePrint}
          </Body>
        </Col>
      </Row>
      {showPopup ? (
        <Popup
          {...popup}
          activationType={ACTIVATION_TYPES.CLICK}
          onCloseCallback={triggerPopup}
        />
      ) : null}
    </GridWrapper>
  );
};

CallToAction.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonUrl: PropTypes.string,
  buttonTrackingId: PropTypes.string,
  buttonOptimizelyFullStackClickEvents: PropTypes.arrayOf(PropTypes.string),
  buttonLabelSecond: PropTypes.string,
  buttonUrlSecond: PropTypes.string,
  buttonTrackingIdSecond: PropTypes.string,
  buttonOptimizelyFullStackClickEventsSecond: PropTypes.arrayOf(
    PropTypes.string,
  ),
  finePrint: PropTypes.string,
  showPricing: PropTypes.bool,
  product: PropTypes.object,
  index: PropTypes.number,
  contentType: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  popup: PropTypes.object,
};

/**
 * @component
 */
export default CallToAction;
