export const ACTIVATION_TYPES = {
  SCROLL: 'Scroll',
  TIME_ON_PAGE: 'Time on page',
  POPUP_BAR: 'Popup Bar',
  CLICK: 'Click',
};

export const POPUP_COOKIE_PREFIX = 'popup_';
export const POPUP_COOKIE_EXPIRATION_TIME_DEFAULT = 30; // days
export const POPUP_COOKIE_EXPIRATION_TIME_DEFAULT_SUCCESS = 365 * 10; // 10 years
